// Свой код jquery
$(document).ready(function () {
    // функция проверки наличия атрибута
    $.fn.hasAttr = function (name) {
        return this.attr(name) !== undefined;
    };


    // Подключаемые функции
    //svg4everybody(); // SVG for Everybody
    lazyload(); // отложенная загрузка
    //range(); // slider цен
    select(); // выпадающие списки
    sticker(); // Стикеры +2 в item


    Fancybox.bind("[data-fancybox]", {
        // Toolbar: false,
        Thumbs: {
            showOnStart: false
        },
        Toolbar: {
            display: {
                right: ["slideshow", "thumbs", "close"],
            },
        },
    }); // Fancybox


    // Размеры адаптивки
    var xs = 359,
        sm = 575,
        md = 767,
        lg = 1023,
        xl = 1279,
        xxl = 1439,
        vw575 = 575,
        vw1599 = 1599;


    // id метрики
    var idmetrika = $('.footer__about').data('number');
    if (idmetrika != '' & idmetrika !== undefined) {
        ClientID = Cookies.get('_ym_uid');

        var ip = $('.form-js input[name="ip"]').val(),
            goal_params = {
                ip: ip,
            };

        ym(idmetrika, 'params', goal_params);
        ym(98054402, 'params', goal_params);
    }


    // отложенная загрузка
    function lazyload() {
        $('.lazyload').Lazy({
            visibleOnly: true,
            effect: 'fadeIn',
        });
        $('.lazyload img').Lazy({
            visibleOnly: true,
            effect: 'fadeIn',
        });
    };



    // выпадающие списки в фильтре
    function select() {
        $('.form-select').each(function () {
            var current = '#' + $(this).attr('id');
            var text_null = $(current + ' .null-checkbox').text();

            // Показать скрыть выпадающий список
            $(this).on('click', '.form-select__arrow', function () {

                var option_all = $('.form-select__option'),
                    //bodyheight = $("body").height(),
                    //halfbodyheight = bodyheight / 2,
                    option_cur = $(current + ' .form-select__option');

                if ($(current).hasClass('active')) {
                    option_cur.slideUp(300);
                    $(current).removeClass('active');
                } else {
                    $('.form-select').removeClass('active');
                    option_all.slideUp(300);
                    option_cur.slideDown(300);
                    $(current).addClass('active');

                    //if ($('body').innerWidth() < lg) {
                    //    var position = option_cur.position().top;
                    //    if (260 >= halfbodyheight) {
                    //        option_all.removeClass("--top");
                    //        option_cur.addClass("--top");
                    //    }
                    //}

                }
            });
            $(this).on('click', '.form-checkbox', function () {
                current_option_filter();
            });

            // Манипуляции со списком
            function current_option_filter() {
                var count = $(current + ' input:checked').length;
                current_value = $(current).find('.form-select__value span');
                if ($(current).hasClass('--count')) {
                    // тип 3 - +5 - дописать класс --count
                    if (count > 0) {
                        current_value.html('<span>+' + count + '</span>');
                    } else {
                        current_value.text(text_null);
                    }
                } else {
                    value_val = [];
                    $(current + ' input:checked').each(function () {
                        value_val.push($(this).val().split("=="));
                        if (value_val.length > 0) {
                            var count_arr = count - 1;
                            if ($(current).hasClass('--list')) {
                                // тип 2 - один, два, три дописать класс --list
                                current_value.html('<b>' + value_val.join(", ") + '</b>');
                            } else {
                                // по умолчанию
                                // тип 1 - Выбранный элемент +2 - по умолчанию
                                if (count_arr > 0) {
                                    current_value.html('<b>' + value_val[0] + '</b><span>+' + count_arr + '</span>');
                                } else {
                                    current_value.html('<b>' + value_val[0] + '</b>');
                                }
                            }
                        }
                    });
                    if (value_val.length == 0) {
                        current_value.text(text_null);
                    }
                }

                // Снимаем все галочки если стоят
                if (count > 0) {
                    $(current + ' .null-checkbox').removeClass('disabled');
                    $(current).on('click', '.null-checkbox', function () {
                        $(current + ' input:checked').prop('checked', false);
                        current_value.text(text_null);
                        $(current + ' .form-select__option').slideUp(200);
                        $(current).removeClass('active');
                        $(current + ' .null-checkbox').addClass('disabled');
                        $('.bank-item').addClass('active');
                    });
                } else {
                    $(current + ' .null-checkbox').addClass('disabled');
                }

            };
            current_option_filter();

            // Закрываем при клике мимо
            jQuery(function ($) {
                $(document).mouseup(function (e) {
                    var div = $(".form-select");
                    if (!div.is(e.target) && div.has(e.target).length === 0) {
                        $(div).find('.form-select__option').slideUp(200);
                        $(div).removeClass('active');
                    }
                });
            });


        });
    };


    // Программа
    $('.form-select.--program input:checkbox').click(function () {
        var program_id = $(this).attr('id');
        //console.log(program_id);
        $('.bank-item').removeClass('active');
        $('.bank-item[data-program="' + program_id + '"]').addClass('active');
    });

    // Только один отмеченный чекбокс.. дописать класс --one
    $('.form-select.--one input:checkbox').click(function () {
        if ($(this).is(':checked')) {
            $('.form-select.--one input:checkbox').not(this).prop('checked', false);
        }

        $('.form-select .form-select__option').slideUp(200);
        $('.form-select').removeClass('active');
        //console.log('нажали');
    });

    // Только один отмеченный чекбокс.. дописать класс --one
    $('.form-select.--link .form-checkbox').click(function () {
        $('.form-select.--list input').prop('checked', false);
        $(this).find('input').prop('checked', true);
        console.log('Поставил чекед');

        $('.form-select .form-select__option').slideUp(200);
        $('.form-select').removeClass('active');
        //console.log('нажали');
    });

    // Ссылка в выпадающем списке
    $('.form-select').on('click', '.form-link', function () {
        var search = window.location.search,
            href = $(this).attr('href');
        document.location.href = href + search;
        //console.log(href + search);
        return false;
    });

    // Переключатель времени перезвонилки
    $('.form-time--btn input:radio').click(function () {
        var type = $(this).data('time'),
            current = $(this).closest('.--time');

        if ($(this).is(':checked') & type == '1') {
            current.find('.form-time').slideDown(300);
        } else {
            current.find('.form-time').slideUp(300);
        }
    });



    // Скрыть стикеты
    function sticker() {
        $('.stick-count').each(function () {
            var show = 2,
                sticker = $(this).children('.sticker');

            if (sticker.length > show) {
                var count = sticker.length - show;
                sticker.slice(show).addClass('hide');
                $(sticker).eq(1).after('<li class="sticker view-sticker">+' + count + '</li>');

                $(this).children('.hide').wrapAll(function () {
                    if (sticker.hasClass('hide'))
                        return "<div class='sticker__more'></div>";
                });

                var offset = $(this).children('.view-sticker').position().left;
                $(this).children('.sticker__more').css("left", offset);

                $(this).on('click', '.view-sticker', function (event) {
                    event.preventDefault();
                });
            }
        });
    };


    // Ресайз
    $(window).on("resize", function () {
        //console.log($('body').innerWidth());
    });

    // Показать скрыть пункты фильтра
    function more_filter() {
        $('.filter-block').each(function () {
            var field = $(this).find('.filter-field');

            $(this).on('click', '.--more', function () {
                if (field.hasClass('show')) {
                    field.addClass('--slide');
                    $(this).find('span').text('Все фильтры');

                    setTimeout(function () {
                        field.removeClass('show').removeClass('--slide');
                    }, 300);
                } else {
                    field.addClass('show');
                    $(this).find('span').text('Скрыть');
                }
            });
        });
    };
    more_filter();


    // показать скрыть фильтр
    $('.filter-block.--popup').each(function () {
        var current = $(this);
        $('.catalog').on('click', '.view-filter', function () {
            current.addClass('show');
            return false;
        });
        $('.filter-block.--popup').on('click', '.btn-close, .--close', function () {
            current.addClass('--slide');
            setTimeout(function () {
                current.removeClass('show').removeClass('--slide');
            }, 300);
        });
    });


    // Круги при наведении
    $(".button, .--btn-tab").on('mouseenter', function (event) {
        $(".button .ripple").remove();
        $(this).append("<span class='ripple'><span>");
        $(".ripple").css("left", event.pageX - $(this).offset().left);
        $(".ripple").css("top", event.pageY - $(this).offset().top);
    });


    // показать скрыть попап
    $('body').on('click', '.popup-view', function () {
        var current = '#' + $(this).data('id');

        if ($(this).hasAttr('data-title')) {
            $(current + ' .form-hide .popup-form__title').html($(this).data('title'));
        };
        if ($(this).hasAttr('data-desc')) {
            $(current + ' .form-hide .popup-form__desc').html($(this).data('desc'));
        }
        $(current + ' .form-metka').val($(this).data('metka'));
        $(current + ' .form-btn-name').val($(this).find('span').text());
        $(current + ' .form-button').text($(this).data('button'));

        $(current).css('display', 'flex').hide().fadeIn(300).addClass('show');

        var metrika = $(this).data('target');
        if (idmetrika != '' & idmetrika !== undefined) {
            ym(idmetrika, 'reachGoal', metrika); // Открытие формы с меткой кнопки

            var goal_popup = {
                open_popup: metrika,
            };
            ym(idmetrika, 'params', goal_popup);
            ym(98054402, 'params', goal_popup);
        }

        return false;
    });

    $('.popup').on('click', '.popup-close, .filter-close, .popup-close--2', function () {
        var current = '#' + $(this).closest('.popup').attr('id');
        //$('body').removeClass('lock');
        $(current).removeClass('show');
        setTimeout(function () {
            $(current).fadeOut(300);
        }, 250);
    });


    // Отправка формы
    $('.form-js').each(function () {
        var cur_form = '#' + $(this).attr('id');
        $(this).on('click', '.form-button', function () {

            var valid_phone = $(cur_form + ' input[name=phone]').val().substring(4, 5);

            if (valid_phone == '9') {
                //console.log(valid_phone);
                //console.log('Все норм');
                $(cur_form + ' span[data-error=phone]').hide();

                document.addEventListener('fetchit:success', (e) => {
                    const { form, response } = e.detail;
                    //form.style.display = 'none'; // Скрываем форму!

                    // Текст про рабочее время после отправки
                    var time_call = $(cur_form).find('input[name="time"]:checked').data('time'),
                        text_time = $(cur_form).closest('.form-hide').siblings('.form-ok').find('.form-tranks__desc').data('time'),
                        text_work = $(cur_form).closest('.form-hide').siblings('.form-ok').find('.form-tranks__desc').data('work');
                    if (time_call == '1') {
                        $(cur_form).closest('.form-hide').siblings('.form-ok').find('.form-tranks__desc').text(text_time);
                    } else {
                        $(cur_form).closest('.form-hide').siblings('.form-ok').find('.form-tranks__desc').text(text_work);
                    }

                    $(cur_form).closest('.form-hide').hide();
                    $(cur_form).closest('.form-hide').siblings('.form-ok').fadeIn(100).css('display', 'flex');

                    //setTimeout(function () {
                    //$(cur_form).show();
                    //    $(cur_form).closest('.form-hide').fadeIn(100).css('display', 'flex');
                    //    $(cur_form).closest('.form-hide').siblings('.form-ok').hide();
                    //}, 5000);

                    var target = $(cur_form).data('target');

                    if (idmetrika != '' & idmetrika !== undefined) {

                        // Получаетм параметры
                        var mesto = $(cur_form).find('.form-metka').val(),
                            popup = $(cur_form).find('.form-popup').val(),
                            btn_name = $(cur_form).find('.form-btn-name').val(),
                            yclid = $(cur_form).find('input[name="yclid"]').val(),
                            ip = $(cur_form).find('input[name="ip"]').val(),
                            time = $(cur_form).find('input[name="time"]').val(),
                            source = $(cur_form).find('input[name="utm_source"]').val(),
                            goal_order = {
                                order_mesto: mesto,
                                order_button_name: btn_name,
                                order_popup: popup,
                                order_ip: ip,
                                order_yclid: yclid,
                                order_clientid: ClientID,
                                order_source: source,
                                order_time: time,
                            };
                        //console.log(goal_order);

                        ym(idmetrika, 'reachGoal', target, goal_order); // Цель из формы

                        ym(idmetrika, 'reachGoal', 'all_orders', goal_order); // Все заявки
                        ym(98054402, 'reachGoal', 'all_orders', goal_order); // Своя метрика

                        // проверка на повторную заявку
                        if (!Cookies.get('double_order_cookie') == true) {
                            Cookies.set('double_order_cookie', true, { expires: 10, path: '/' });
                        } else {
                            ym(idmetrika, 'reachGoal', 'double_orders', goal_order);
                            ym(98054402, 'reachGoal', 'double_orders', goal_order); // Своя метрика
                            //console.log('повтораня заявка');
                        }
                    }

                    return false;
                });

            } else {

                //console.log(valid_phone);
                //console.log('Номер телефона введен не правильно');
                $(cur_form + ' span[data-error=phone]').text('Проверьте правильность номера').show();
                return false;

            }

        });
        // Антиспам - добавление обязательного поля
        $(this).append('<input type="text" name="org" value="" class="validate-input" />');
    });



    // Адаптивная таблица
    $('.content-text table').each(function () {
        $(this).wrap('<div class="table-wrap scroll--hide"></div>');
    });

    // Меню показать ЕЩЕ
    $('.more-menu').moreNav();

    // показать скрыть меню
    $('body').on('click', '.view-menu', function () {
        var block = $('.header-top');
        if (block.hasClass('show')) {
            block.addClass('--out');
            setTimeout(function () {
                block.removeClass('show --out');
            }, 300);
        } else {
            block.addClass('show');
        }
        return false;
    });

    // показать выпадающее меню в телефоне
    $('.nav').each(function () {
        $(this).on('click', '.drop', function () {
            var drop_name = $(this).prev('a').find('span').text();
            $(this).next('.dropmenu').find('.prev span').text(drop_name);
            $(this).next('.dropmenu').toggleClass('--show');
            //console.log(drop_name);
            return false;
        });
        $(this).on('click', '.prev', function () {
            var block = $(this).parent('.dropmenu')
            block.addClass('--out');
            setTimeout(function () {
                block.removeClass('--show --out');
            }, 300);
            return false;
        });

        if ($('body').innerWidth() + 17 < xl) {
            contents = $('.header-bar .header__lang').contents();
            $('.nav').append(contents);
        }
    });


    // всплывающее окно подсказки в телефоне
    $('.--mpopup').each(function () {
        $(this).on('click', '.--btn', function () {
            var current = $(this).closest('.--mpopup');
            current.addClass('--on');
            current.find('.--hide').append('<div class="--overlay"></div>');
            current.find('.--wrap').append('<i class="btn-close"></i>');
            return false;
        });
        $(this).on('click', '.btn-close, .--overlay', function () {
            var current = $(this).closest('.--mpopup');
            current.addClass('--slide');
            setTimeout(function () {
                current.find('.--overlay').remove();
                current.find('.btn-close').remove();
                current.removeClass('--on').removeClass('--slide');;
            }, 500);
        });
    });


    // показать фильтр карты
    $('.infrastructure').each(function () {
        var current = $(this).find('.maps-sort');
        $(this).on('click', '.--view', function () {
            current.addClass('--on');
            return false;
        });
        $(this).on('click', '.--close', function () {
            current.removeClass('--on');
        });
    });


    // Фильтрация элементов isotope
    $('.--filter-item').each(function () {
        var current = $(this);
        current.on('click', 'button[data-filter]', function () {
            var filter = $(this).data('filter'),
                item_all = current.find('div[data-filtered]'),
                item = current.find('div[data-filtered="' + filter + '"]');

            $('button[data-filter]').removeClass('active');
            $(this).addClass('active');

            item_all.hide().removeClass('--i --hide');
            item.css("display", "flex").addClass('--i').removeClass('--hide');;

            if (filter == 'all') {
                item_all.css("display", "flex").addClass('--i --hide');
            }

            view_more('.--load-more');

            return false;
        });
    });


    // Показать еще элементы
    $(window).on("resize", function () {
        if ($('body').innerWidth() + 17 < md) {
            //view_more('.--m-more');
        }
    });

    // Показать еще элементы 
    // Добавить --load-more и data-hide="8"
    function view_more(e) {
        $(e).each(function () {
            var current = $(this),
                count = $(this).data('hide'),
                box = $(this).find('.--i'), // Элементы
                btn = $(this).find('.btn-show'), // Кнопка
                count_all = box.length - count;

            for (let i = count; i < box.length; i++) {
                //box[i].style.display = "none";
                box.eq(i).addClass('--hide').removeAttr('style');
            }

            if (count_all > 0) {
                btn.find('b').text(count_all);
                btn.css("display", "flex");
            } else {
                btn.hide();
            }

            var countD = count;
            btn.on("click", function () {
                countD += count;
                count_all = current.find('.--hide').length;

                if (count_all >= 0) {
                    for (let i = 0; i < countD; i++) {
                        box.eq(i).removeClass('--hide');
                        //    box[i].style.display = "flex";
                    }

                    count_all = current.find('.--hide').length;
                    btn.find('b').text(count_all);
                }

                if (count_all <= 0) {
                    btn.hide();
                }
            })
        });
    }
    if ($('body').innerWidth() + 17 < md) {
        view_more('.--m-load-more');
    }
    // Показать еще и еще ПК
    view_more('.--load-more');


    // показать еще текст в описании
    function load_text() {
        $('.load-text').each(function () {
            var current_block = $(this),
                current = current_block.find('.load-text--wrap'),
                maxheight = current_block.data('maxheight'),
                fullheight = current_block.outerHeight();

            current.css('max-height', maxheight);

            //setTimeout(function () {
            //    console.log(fullheight + '<=' + maxheight + ' - ' + current_block.find('.btn-more').length);
            //    if (maxheight <= fullheight & current_block.find('.btn-more').length == 0) {
            //        current_block.append('<button class="btn-more">Читать полностью</button>');
            //    }
            //}, 350);

            current_block.on('click', '.btn-more', function () {
                current_block.toggleClass('active');

                if (current_block.hasClass('active')) {
                    current.css('max-height', '1000px');
                    $(this).text('Скрыть');

                    setTimeout(function () {
                        var fullheight = current.outerHeight();
                        current.css('max-height', fullheight);
                    }, 500);

                } else {
                    $(this).text('Читать полностью');
                    current.css('max-height', maxheight);
                }

                return false;
            });
        });
    };
    load_text();


    // Закрыть окна клавишей Escape
    $(document).keyup(function (e) {
        if (e.key === "Escape" || e.keyCode === 27) {
            //console.log('Нажата клавиша Escape');
            $('.popup').removeClass('show');
            setTimeout(function () {
                $('.popup').fadeOut(300);
            }, 250);
            $('.popup-maps').fadeOut(300);
        }
    });


    // Слайдер в шапке
    $('.slider-header').each(function () {
        var id = $(this).data('id'),
            current = '.slider-header[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 0,
            mousewheelControl: true,
            slidesPerView: 1,
            lazy: true,
            freemode: true,
            speed: 1000,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            effect: "creative",
            creativeEffect: {
                prev: {
                    shadow: true,
                    translate: ["-25%", 0, -1],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            },
        });
    });


    // Слайдер в шапке фото
    $('.slider-header-2').each(function () {
        var id = $(this).data('id'),
            current = '.slider-header-2[data-id="' + id + '"]';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 0,
            slidesPerView: 1,
            lazy: true,
            loop: true,
            freemode: true,
            speed: 1000,
            autoplay: {
                delay: 6000,
            },
            effect: "fade",
        });
    });


    // Слайдер в шапке
    $('.slider-about').each(function () {
        var id = $(this).data('id'),
            current = '.slider-about[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 0,
            mousewheelControl: true,
            slidesPerView: 1,
            lazy: true,
            freemode: true,
            speed: 500,
            direction: "vertical",
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
        });
    });


    // Слайдер на плане этажа
    $('.slider-level').each(function () {
        var id = $(this).data('id'),
            current = '.slider-level[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 0,
            slidesPerView: 3,
            //centeredSlides: true,
            direction: "vertical",
            lazy: true,
            freemode: true,
            speed: 500,
            /*navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },*/
            /*breakpoints: {
                0: {
                    slidesPerView: 1,
                    allowTouchMove: true,
                },
                767: {
                    slidesPerView: 3,
                    allowTouchMove: true,
                },
            },*/
        });
    });


    // Слайдер по 5 штук
    $('.slider-five').each(function () {
        var id = $(this).data('id'),
            current = '.slider-five[data-id="' + id + '"]',
            sw_dots = current + ' .swiper-pagination',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            slidesPerView: 5,
            spaceBetween: 16,
            freemode: true,
            lazy: true,
            mousewheelControl: true,
            keyboardControl: true,
            speed: 500,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            pagination: {
                el: sw_dots,
                type: 'bullets',
            },
            breakpoints: {
                0: {
                    slidesPerView: "auto",
                    pagination: {
                        el: sw_dots,
                        type: 'bullets',
                        clickable: true,
                        //dynamicBullets: true,
                    },
                },
                767: {
                    slidesPerView: 3,
                },
                1023: {
                    slidesPerView: 4,
                },
                1279: {
                    slidesPerView: 5,
                },
            },
        });
    });


    // Слайдер по 4 штуки
    $('.slider-four').each(function () {
        var id = $(this).data('id'),
            current = '.slider-four[data-id="' + id + '"]',
            sw_dots = current + ' .swiper-pagination',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 16,
            slidesPerView: 4,
            lazy: true,
            freemode: true,
            //allowTouchMove: true,
            speed: 500,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            pagination: {
                el: sw_dots,
                type: 'bullets',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 8,
                },
                575: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                1023: {
                    slidesPerView: 3,
                },
                1439: {
                    slidesPerView: 4,
                },
            },
        });
    });


    // Слайдер по 3 штуки
    $('.slider-three').each(function () {
        var id = $(this).data('id'),
            current = '.slider-three[data-id="' + id + '"]',
            sw_dots = current + ' .swiper-pagination',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 16,
            slidesPerView: 3,
            lazy: true,
            freemode: true,
            //allowTouchMove: true,
            speed: 500,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            pagination: {
                el: sw_dots,
                type: 'bullets',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 8,
                },
                620: {
                    slidesPerView: 2,
                },
                1023: {
                    slidesPerView: 3,
                }
            },
        });
    });


    // Табы
    function tabs(e) {
        $(e).on("click", function () {
            var tabs = $(this).closest('.tabs-init'),
                wrap = tabs.find('.tabs-btn'),
                current = $(this).data('id');

            tabs.find('.btn-tab').removeClass('active');
            $(this).addClass('active');
            tabs.find('.tab').removeClass('active');

            setTimeout(function () {
                tabs.find('.tab').hide();
                tabs.find('.tab-' + current).show().addClass('active');
                //load_text();
            }, 300);

            if (!$(this).hasClass('.active')) {
                tabs.find('.btn-tab').removeClass('here');
                tabs.find('.--swipe', tabs).css({
                    left: $(this).offset().left - wrap.offset().left,
                    width: $(this).outerWidth()
                });

                //var ileft = (offset.left - block.offset().left) / block.innerWidth() * 100;
            }

            setTimeout(function () {
                $('.--sticky').each(function () {
                    stickySidebar.updateSticky();
                });
            }, 1000);

        });
    };
    tabs('.btn-tab');


    // Табы при наведении
    function tabs_hover(e) {
        $(e).hover(function () {
            var tabs = $(this).closest('.tabs-init'),
                current = $(this).data('id');
            tabs.find('.btn-tab').removeClass('active');
            $(this).addClass('active');
            tabs.find('.tab').hide().removeClass('active');
            tabs.find('.tab-' + current).fadeIn('300').addClass('active');
        });
    };
    tabs_hover('.tabs-hover .btn-tab');


    // Подробности у ремонта
    function remont() {
        $('.remont-detail__btn').on("click", function () {
            var current = $(this).parent('.remont-detail');
            if (!current.hasClass('active')) {
                $('.remont-detail').removeClass('active');
                current.addClass('active');
            } else {
                current.removeClass('active');
            }

        });
    };
    remont();


    // Слайдер в особенностей
    $('.slider-properties').each(function () {
        var id = $(this).data('id'),
            current = '.slider-properties[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 8,
            mousewheelControl: true,
            slidesPerView: 1,
            lazy: true,
            freemode: true,
            speed: 500,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            //effect: "creative",
            //creativeEffect: {
            //    prev: {
            //        shadow: true,
            //       translate: ["-20%", 0, -1],
            //    },
            //    next: {
            //        translate: ["100%", 0, 0],
            //    },
            //},
        });
    });


    // Слайдер в особенностей 2
    $('.slider-properties-2').each(function () {
        var id = $(this).data('id'),
            current = '.slider-properties-2[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';

        var swiper_2 = new Swiper(current + ' .swiper-dependent', {
            spaceBetween: 8,
            slidesPerView: 1,
            lazy: true,
            freemode: true,
            speed: 500,
            effect: "fade",
            allowTouchMove: false,
            parallax: true,
            breakpoints: {
                0: {
                    autoHeight: true,
                },
                1023: {
                    autoHeight: false,
                }
            },
        });
        var swiper = new Swiper(current + ' .swiper', {
            spaceBetween: 8,
            mousewheelControl: true,
            slidesPerView: 1,
            freeMode: true,
            speed: 500,
            lazy: true,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            //effect: "creative",
            //creativeEffect: {
            //    prev: {
            //        shadow: true,
            //       translate: ["-20%", 0, -1],
            //    },
            //    next: {
            //        translate: ["100%", 0, 0],
            //    },
            //},
            thumbs: {
                swiper: swiper_2,
            },
        });

    });


    // Локации инфраструктуры из swiper
    $('.slider-locations').each(function () {
        var id = $(this).data('id'),
            current = '.slider-locations[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            slidesPerView: "auto",
            spaceBetween: 0,
            lazy: true,
            freemode: true,
            speed: 500,
            mousewheelControl: true,
            keyboardControl: true,
            centeredSlidesBounds: true,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
        });
    });


    // Кнопки инфраструктуры из swiper
    $('.slider-maps').each(function () {
        var id = $(this).data('id'),
            current = '.slider-maps[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            slidesPerView: "auto",
            spaceBetween: 8,
            lazy: true,
            freemode: true,
            speed: 500,
            mousewheelControl: true,
            keyboardControl: true,
            centeredSlidesBounds: true,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
        });
    });


    // Слайдер расположения swiper
    $('.slider-mood').each(function () {
        var id = $(this).data('id'),
            current = '.slider-mood[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            slidesPerView: 3,
            lazy: true,
            spaceBetween: 16,
            freemode: true,
            mousewheelControl: true,
            keyboardControl: true,
            speed: 500,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    allowTouchMove: true,
                },
                767: {
                    slidesPerView: 2,
                    allowTouchMove: true,
                },
                1023: {
                    slidesPerView: 1,
                    allowTouchMove: true,

                },
                1279: {
                    slidesPerView: 3,
                    allowTouchMove: false,
                },
            },
        });
        if ($(current + " .swiper-slide").length < 3) {
            $(current + " .swiper-control--wrap").hide();
        };
        // beforeTransitionStart более
        swiper.on('slideChange', function () {
            if (swiper.realIndex == '0') {
                $('.mood__maps').removeClass('change');

            } else {
                $('.mood__maps').addClass('change');
            }

            lazyload();
        })
        swiper.on('reachBeginning', function () {
            $('.mood__maps').removeClass('change');
        });
    });


    // Посчитаем кол-во фото в месяце
    $('.progress-item--wrap').each(function () {
        count = $(this).find('.progress-item__photo').length;
        $(this).find('.progress-count').text(count + 1);

        if (count < 2) {
            $(this).find('.progress-item__count').hide();
        }
    });


    // Слайдер акций в шапке
    $('.slider-one').each(function () {
        var id = $(this).data('id'),
            current = '.slider-one[data-id="' + id + '"]',
            effect = $(current).data('effect'),
            sw_dots = current + ' .swiper-pagination',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            slidesPerView: 1,
            lazy: true,
            //breakpointsBase: 'container',
            spaceBetween: 0,
            freemode: true,
            mousewheelControl: true,
            keyboardControl: true,
            speed: 500,
            loop: true,
            //grabCursor: true,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            pagination: {
                el: sw_dots,
                type: 'bullets',
            },
            autoplay: {
                delay: 5000,
            },
            effect: effect,
            //creativeEffect: {
            //    prev: {
            //        shadow: true,
            //       translate: ["-20%", 0, -1],
            //    },
            //    next: {
            //        translate: ["100%", 0, 0],
            //    },
            //},
        });
    });


    // Галерея 
    $('.slider-gallery').each(function () {
        var id = $(this).data('id'),
            current = '.slider-gallery[data-id="' + id + '"]',
            sw_dots = current + ' .swiper-pagination',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev';
        var swiper = new Swiper(current + ' .swiper', {
            slidesPerView: 1,
            lazy: true,
            //breakpointsBase: 'container',
            spaceBetween: 8,
            freemode: true,
            mousewheelControl: true,
            keyboardControl: true,
            speed: 500,
            //grabCursor: true,
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
            pagination: {
                el: sw_dots,
                type: 'bullets',
            },
            autoplay: {
                delay: 5000,
            },
            //effect: "creative",
            //creativeEffect: {
            //    prev: {
            //        shadow: true,
            //       translate: ["-20%", 0, -1],
            //    },
            //    next: {
            //        translate: ["100%", 0, 0],
            //    },
            //},
        });
        if ($(current + " .swiper-slide").length < 1) {
            $(current + " .swiper-control--wrap").hide();
        };
    });


    // Шахматка
    $('.scrollbar-chess').each(function () {
        var id = $(this).data('id'),
            current = '.scrollbar-chess[data-id="' + id + '"]',
            sw_next = current + ' .swiper-button-next',
            sw_prev = current + ' .swiper-button-prev',
            scrollbar = current + ' .swiper-scrollbar';
        var swiper = new Swiper(current + ' .swiper', {
            direction: "horizontal",
            slidesPerView: "auto",
            freeMode: true,
            //mousewheelControl: true,
            mousewheel: true,
            scrollbar: {
                el: scrollbar,
                draggable: true,
                snapOnRelease: false,
                //hide: true,
            },
            navigation: {
                nextEl: sw_next,
                prevEl: sw_prev,
            },
        });
        swiper.mousewheel.disable();
    });


    // Показать пин на шахматке если он есть
    function status_chess() {
        $('.chess-item').each(function () {
            var item = $(this).data('i'),
                id = $('#' + item).attr('id'),
                id_status = $('#' + item).attr('data-status');

            //console.log(item + ' = ' + id  + ' = ' + id_status);			
            if (id != undefined) {
                $(this).removeClass('--sold');

                switch (id_status) {
                    case 'sale':
                        $(this).addClass('--dot');
                        //item.addClass('--sale');
                        console.log("--dot");
                        break;
                    case 'booked':
                        $(this).addClass('--lock');
                        console.log("--booked");
                        break;
                }
            } else {
                $(this).removeClass('--dot --lock --sale').addClass('--sold');
            }
        });
    };
    status_chess();


    // Галерея для телефона
    if ($('body').innerWidth() + 17 < lg) {
        $('.slider-mobile').each(function () {
            var id = $(this).data('id'),
                current = '.slider-mobile[data-id="' + id + '"]',
                sw_dots = current + ' .swiper-pagination',
                sw_next = current + ' .swiper-button-next',
                sw_prev = current + ' .swiper-button-prev';
            var swiper = new Swiper(current + ' .swiper', {
                slidesPerView: 2,
                lazy: true,
                breakpointsBase: 'container',
                spaceBetween: 8,
                freemode: true,
                mousewheelControl: true,
                keyboardControl: true,
                speed: 500,
                navigation: {
                    nextEl: sw_next,
                    prevEl: sw_prev,
                },
                pagination: {
                    el: sw_dots,
                    type: 'bullets',
                },
                autoplay: {
                    delay: 5000,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },
                    767: {
                        slidesPerView: 2,
                    },
                },
            });
        });
    }


    // Инфраструктура
    // Подгрузка скриптка карты и инициализация
    function load_api() {
        $('.maps-block').each(function () {
            //var apikey = '285a4384-a3e2-4f92-ac29-c1ca26cf5083';
            var apikey = $(this).data('api'),
                lang = $(this).data('lang');
            setTimeout(function () {
                $.getScript('https://api-maps.yandex.ru/2.1/?apikey=' + apikey + '&lang=' + lang, function () {
                    ymaps.ready(yandexmaps);
                });
            }, 3500);
        });
    };
    load_api();


    // Только один отмеченный чекбокс с городом
    $('.group-city').on('click', '.input-checkbox', function () {
        var maps_center = JSON.parse('[' + $(this).data('maps-center') + ']');
        //myMap.panTo(maps_center);
        //$('.object-maps--link').attr({'data-coords-center': maps_center, 'data-link': maps_link});
        $('.object-maps--link').attr('data-coords-center', maps_center);
    });


    // определение города
    function city_init() {
        ymaps.geolocation.get({ provider: "yandex" })
            .then(function (res) {
                var g = res.geoObjects.get(0);
                $(".form-city").val(g.getCountry() + ', ' + g.getAdministrativeAreas()[0] + ', ' + g.getLocalities()[0]);
            })
            .catch(function (err) {
                $(".form-city").val('Не удалось установить местоположение', err);
            });
    };
    // Опредление гео при клике на телефон
    $('input[name="phone"]').on("click", function () {
        ymaps.ready(city_init);
    });


    // яндекс карта
    function yandexmaps() {
        $('.maps-block').each(function () {
            var current = $(this).find('.maps').attr('id'),
                coords = JSON.parse('[' + $(this).data('coords-center') + ']'),
                name = $(this).data('name'),
                link_json = $(this).data('link'),
                maps_json = $('.get_json_maps').text(),
                zoom = $(this).data('zoom'),
                clusterize = $(this).data('clusterize'),
                pin = $(this).data('pin');

            var myMap = new ymaps.Map(current, {
                center: coords,
                zoom: zoom,
                controls: []
            }, {
                geolocationControlFloat: 'right',
                zoomControlSize: 'small',
                searchControlProvider: 'yandex#map',
                //autoFitToViewport: 'always', // автоцентровка.. но прыгает
            });

            //myMap.container.fitToViewport(); // автоцентровка..

            myMap.controls.add(
                new ymaps.control.ZoomControl({
                    options: { position: { right: 0 } }
                }),
                new ymaps.control.SearchControl({
                    noPlacemark: true
                })
            );
            //myMap.behaviors.disable( [ 'scrollZoom', 'drag', 'rightMouseButtonMagnifier' ] ); // Удалить все элементы управления
            myMap.behaviors.disable('scrollZoom'); // открычить увеличение скролом
            //myMap.controls.remove('routeEditor'); // скрыть какое нибудь поле управления
            //myMap.behaviors.disable('drag'); // отключить перетаскивание


            // Главный пин
            if (coords != '' || coords != 'undefined') {
                // создание своего пина.. можно дописать параметры типа $[properties.class]
                var houseLayout = ymaps.templateLayoutFactory.createClass('<div class="pin-container"><div class="pin-house"><img src="$[properties.img]" alt=""><i></i></div></div>');

                var housePlacemark = new ymaps.Placemark(coords, {
                    hintContent: '<div class="maps-hint">' + name + '</div>',
                    img: pin,
                    class: '', // получаем эти параметры
                    //balloonContent: '<div class="maps-balloon">' + name + '</div>',
                    //hintContent: 'Собственный значок метки',
                    openBalloonOnClick: false, // не открывать балун да/нет
                    balloonPanelMaxMapArea: 0, // В данном примере балун никогда не будет открываться в режиме панели.
                }, {
                    iconLayout: houseLayout,
                    zIndex: 1000,
                    // область наведения
                    iconShape: {
                        type: 'Circle',
                        // Круг описывается в виде центра и радиуса горизонталь, вертикаль
                        coordinates: [-2, -40],
                        radius: 40
                    }
                });
                // Добавляем метку на карту
                myMap.geoObjects.add(housePlacemark);

                housePlacemark.events.add('mouseenter', function (e) {
                    //var target = e.get('target');
                    //target.options.set('iconImageHref', '/assets/img/-1hover.svg');
                    $('.pin-container').addClass('hover');
                });
                housePlacemark.events.add('mouseleave', function (e) {
                    $('.pin-container').removeClass('hover');
                });
            };


            // Объект из параметров блока простой
            /*if (link_json !== '') {
                myMap.geoObjects.add(new ymaps.Placemark(myMap.getCenter(), {
                    balloonContent: '<div class="maps-wrap"><div class="mapsItem__name">' + pin_just + '</div></div>',
                    //hintContent: 'Собственный значок метки',
                    openBalloonOnClick: true, // не открывать балун да/нет
                    balloonPanelMaxMapArea: 0, // В данном примере балун никогда не будет открываться в режиме панели.
                }, {
                    iconLayout: 'default#image',
                    iconImageHref: "/assets/img/svg/pin.svg",
                    iconImageSize: [48, 48],
                    iconImageOffset: [-24, -48],
                    //iconImageHref: pin,
                    //iconImageSize: [96, 96],
                    //iconImageOffset: [-48, -96],
                    zIndex: 1000
                }));
            }*/

            // objectManager - для кластеризации
            /*if (link_json !== '') {
                objectManager = new ymaps.ObjectManager({
                    clusterize: clusterize,
                    gridSize: 20,
                    clusterDisableClickZoom: false, // не увеличивать при клике
                    clusterBalloonContentLayout: "cluster#balloonAccordion", // акардион в балуне
                    clusterBalloonCycling: false,
                    clusterBalloonPanelMaxMapArea: 0, // В данном примере балун никогда не будет открываться в режиме панели.
                    openBalloonOnClick: clusterize, // не открывать балун
                    clusterOpenBalloonOnClick: true, // не открывать балун
                    clusterBalloonAccordionShowIcons: false,  // не открывать иконку в балуне
                    clusterIconLayout: ymaps.templateLayoutFactory.createClass('<div class="clusterIcon">{{ properties.geoObjects.length }}</div>'),
                    clusterIconShape: {
                        type: 'Rectangle',
                        coordinates: [[36, 36], [-18, -36]]
                    },
                });
                objectManager.objects.options.set('preset', {
                    iconImageHref: "/assets/img/svg/pin.svg",
                    iconLayout: "default#image",
                    iconImageSize: [48, 48],
                    iconImageOffset: [-24, -48]
                });
     
                // Подгрузка объектов по ссылке
                myMap.geoObjects.add(objectManager);
                $.ajax({
                    url: link_json
                }).done(function (data) {
                    objectManager.add(data);
                });
            }*/


            // Подгрузка объектов по ссылке
            if (link_json !== '') {
                setTimeout(function () {
                    $.ajax({
                        url: link_json
                    }).done(function (data) {
                        window.infrastructure = ymaps.geoQuery(data).addToMap(myMap);
                    });
                }, 2000);
            }

            // Подгрузка объектов из блока
            if (maps_json.length > 4) {
                window.infrastructure = ymaps.geoQuery(maps_json).addToMap(myMap);
            }


            // Фильтр инфраструктуры
            $('.maps-pin, .all-pin').on('click', function () {
                var shownObjects,
                    check_pin = new ymaps.GeoQueryResult(),
                    all_pin = new ymaps.GeoQueryResult();

                // в цикле собираем все нажатые id
                check_pin = $('.maps-pin').map(function () {
                    if ($(this).children('input').prop('checked')) {
                        var pin = $(this).data('type');
                        return check_pin = infrastructure.search('properties.clusterCaption = "' + pin + '"').add(check_pin);
                    }
                }).get();

                // неведомая хрень.. хз что она делает.. но без нее не работает
                check_pin = infrastructure.search('properties.clusterCaption = ""').add(check_pin);

                // все элементы
                all_pin = infrastructure.search('geometry.type = "Point"');

                // Покажем все пины если нажать все
                if ($('.all-pin').children('input').prop('checked')) {
                    check_pin = infrastructure.search('geometry.type = "Point"').add(check_pin);
                }

                if ($(this).data('all') == '1') {
                    $('.maps-pin').children('input').prop('checked', false);
                } else {
                    $('.all-pin').children('input').prop('checked', false);
                }

                shownObjects = check_pin.intersect(all_pin).addToMap(myMap);
                infrastructure.remove(shownObjects).removeFromMap(myMap);
            });
            // Конец фильтра инфраструктуры





            // Обрабатываем событие открытия балуна на геообъекте
            myMap.events.add('balloonopen', function (e) {
                rank();
                lazyload();
            });



            // Разблокировать перетаскивание карты
            /*
            var toggleLocked = document.querySelector('.locked-maps');
            myMap.behaviors.disable('drag'); // Заблокировано
     
            toggleLocked.addEventListener('click', function () {
                if (myMap.behaviors.isEnabled('drag')) {
                    myMap.behaviors.disable('drag');
                    toggleLocked.querySelector('span').textContent = 'Разблокировать карту';
                } else {
                    myMap.behaviors.enable('drag');
                    toggleLocked.querySelector('span').textContent = 'Заблокировать карту';
                }
            });
     
            // Перемещение по карте
            $('.contact-block').on('click', '.button-group button', function () {
                var coords_center = JSON.parse('[' + $(this).data('coords-center') + ']');
                myMap.panTo(coords_center, {
                    delay: 500
                });
            });
            */


            // центруем карту по добавленным объектам
            //myMap.setBounds(myMap.geoObjects.getBounds()); 

            // Построение маршрута на карте
            //controls: ['routeButtonControl'] // в самое начало
            //var control = myMap.controls.get('routeButtonControl');
            // Зададим координаты пункта отправления с помощью геолокации.
            // control.routePanel.geolocate('from');
            //control.routePanel.state.set({
            //    type: "pedestrian",
            //    to: '51.523986, 46.018446'
            //});
            // Откроем панель для построения маршрутов.
            //control.state.set('expanded', true);

        });
    };


    // Переключение с table на grid
    // Показать заголовки к таблице квартир
    $('.catalog-type').on('click', '.button', function () {
        var type = $(this).data('type');
        if (type == '--table') {
            $('.catalog-block .table-header').slideDown();
        } else {
            $('.catalog-block .table-header').slideUp();
        }
        //$('#mse2_results').removeClass('--grid --table').addClass(type);
    });


    // Очистить фильтр
    $('.--clean').on('click', function () {
        var filter = '.form-js-fitter';
        $(filter + ' .form-select__value span').text('Не выбрано');
        // setTimeout(function () {
        //range_price_filter();
        //}, 60);
    });


    // Ипотечный калькулятор
    // Ползунки
    $('.form-range.--one').each(function () {
        var current = '#' + $(this).attr('id'),
            min = $(this).data('min'),
            max = $(this).data('max'),
            step = $(this).data('step'),
            now = $(current).find('input').val();

        // Показать 30% от суммы
        $('.calc-vznos').each(function () {
            var price_start = $(".calc-price").val(),
                percent_start = $(".calc-vznos-percent").data('percent'),
                vznos_percent_one = parseFloat(price_start * percent_start / 100).toFixed(0);
            $(this).val(vznos_percent_one);
            $(this).prev('.uprank').val(vznos_percent_one);
        });

        // ползунок стоимости квартиры
        $(current + " .range").slider({
            range: "min",
            value: now,
            min: min,
            max: max,
            step: step,
            slide: function (event, ui) {

                //$(current + " .filter__price").text(ui.value);
                $(current).find('input').val(ui.value);

                var price = $(".calc-price").val(),
                    vznos = $(".calc-vznos").val(),
                    period = $(".calc-period").val();

                if ($(this).hasClass('range--price')) {
                    // Показать какой процент от суммы
                    var vznos_percent = parseFloat(vznos / ui.value * 100).toFixed(0);
                    $('.calc-vznos-percent').each(function () {
                        $(this).text(vznos_percent + '%');
                    });

                    // Максимальный взнос 90% от суммы
                    $('.calc-vznos-max').each(function () {
                        var vznos_max = parseFloat(ui.value * 0.9).toFixed(0),
                            vznos_min = parseFloat(ui.value * 0.15).toFixed(0);
                        $('.range--vznos').slider("option", "max", vznos_max);
                        //console.log(vznos + ' - ' + price);
                        if (vznos > price) {
                            $('.calc-vznos').val(vznos_min);
                            $('.calc-vznos').prev('.uprank').val(vznos_min.replace(/\B(?=(\d{3})+(?!\d))/g, ' '));

                            //$(cur + ' .filter__price').text(vznos_max);
                        } else {
                            $('.calc-vznos').val(vznos);
                        };
                    });
                };


                if ($(this).hasClass('range--vznos')) {
                    // Показать какой процент от суммы
                    var vznos_percent = parseFloat(ui.value / price * 100).toFixed(0);
                    $('.calc-vznos-percent').each(function () {
                        $(this).text(vznos_percent + '%');
                    });

                    var vznos_max = parseFloat(price * 0.9).toFixed(0);
                    $('.range--vznos').slider("option", "max", vznos_max);
                };

                if ($(this).hasClass('range--srok')) {
                    $('.calc-srok-decl').text(declination(ui.value, [' год', ' года', ' лет']));
                }

                calculator();
                rank();
                uprank();
            },
            //stop: function(event, ui) {}
        });

    });


    // Формула расчета ипотеки
    function calculator() {
        var price = $(".calc-price").val(),
            vznos = $(".calc-vznos").val(),
            period = $(".calc-period").val();

        var program = $('.--program input[name=bank]:checked').attr('id');
        //console.log('программа ' + program);

        $('.calc-init').each(function () {
            var current = '#' + $(this).attr('id'),
                rate = $(this).data('procent'),
                period_bank = $(this).data('period'),
                program_bank = $(this).data('program'),
                sum = parseFloat(price - vznos).toFixed(0);

            //console.log('банк  ' + current);
            //console.log('сумма  ' + price);
            //console.log('ставка ' + rate);
            //console.log('период  ' + period);
            //console.log('сумма кредита ' + sum);
            var i,
                koef,
                result;
            // ставка в месяц
            i = (rate / 12) / 100;
            // коэффициент аннуитета
            koef = (i * (Math.pow(1 + i, period * 12))) / (Math.pow(1 + i, period * 12) - 1);
            // итог
            result = sum * koef;
            //console.log('сумма в месяц ' + result.toFixed());

            if (result > 0) {
                $(current + ' .price-mount').text(result.toFixed());
            } else {
                $(current + ' .price-mount').text('0');
            };

            //$(current + ' .price-mount').text(result.toFixed());

            rank();

            if (program == program_bank & period <= period_bank) {
                $(current).addClass('active');
            } else if (program == program_bank & period >= period_bank) {
                $(current).removeClass('active');
            };
        });
    };


    // Показать еще или не еще
    function show_more() {
        $('.show-more').on('click', function () {
            $(this).toggleClass('active');
            $(this).prev().slideToggle();
            return false;
        });
    };
    show_more();


    // разбивка суммы на тысячные
    function rank() {
        document.querySelectorAll('.rank').forEach(function (element) {
            var rank = element.textContent;
            element.textContent = rank.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        });
    }
    rank();

    // разбивка суммы на в input
    function uprank000() {
        document.body.addEventListener('input', function (event) {
            if (event.target.classList.contains('uprank')) {
                var rank_val = event.target.value.replace(/[^\0-9\.]/g, '');
                event.target.value = rank_val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
        });
        document.querySelectorAll('.uprank').forEach(function (element) {
            var rank = element.value;
            element.value = rank.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        });
    }

    // разбивка суммы на разряды в input
    function digits_float(target) {
        val = $(target).val().replace(/[^0-9.]/g, '');
        if (val.indexOf(".") != '-1') {
            val = val.substring(0, val.indexOf(".") + 3);
        }
        val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        $(target).val(val);
    }

    function uprank() {
        $('.ipoteka').on('input', '.uprank', function (e) {
            digits_float(this);

            // убираем разряды у input
            val = $(this).val();
            $(this).next('.uprank-del').val(val.replace(/[^0-9.]/g, ''));
            $(this).closest('.form-range').find('.range').slider("value", val.replace(/[^0-9.]/g, ''));
            //$('.range--vznos').slider("option", "value", val.replace(/[^0-9.]/g, ''));

            calculator();
        });
        $('.ipoteka .uprank').each(function () {
            digits_float(this);
        });

    }
    uprank();


    // Только один отмеченный чекбокс с городом
    $('.group-city').on('click', '.input-checkbox', function () {
        var maps_center = JSON.parse('[' + $(this).data('maps-center') + ']');
        //myMap.panTo(maps_center);
        //$('.object-maps--link').attr({'data-coords-center': maps_center, 'data-link': maps_link});
        $('.object-maps--link').attr('data-coords-center', maps_center);
    });


    // склонение слов
    function declination(number, titles) {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    function decl_kv() {
        document.querySelectorAll('.decl-kv').forEach(function (el) {
            var count = el.textContent
            var title = declination(count, [' квартира', ' квартиры', ' квартир'])
            $(el).text(count + title);
        })
    }; decl_kv();
    function decl_kv2() {
        document.querySelectorAll('.decl-kv2').forEach(function (el) {
            var count = el.textContent
            var title = declination(count, [' квартиру', ' квартиры', ' квартир'])
            $(el).text(count + title);
        })
    }; decl_kv2();









    // SVG на плане
    // Показать квартиру при наведении
    function hover_popup(e) {
        $(e).on({
            mouseenter: function () {
                var i = $('#' + $(this).data('i'));
                i.show();
            },
            mouseleave: function () {
                var i = $('#' + $(this).data('i'));
                i.hide();
            },
            mousemove: function (e) {
                var i = $('#' + $(this).data('i'));
                i.css({ top: e.pageY, left: e.pageX });
            }
        });
    };

    // Показать квартиру при клике
    function click_popup(e) {
        $(e).on('click', function () {
            var i = $(this).data('i'),
                item = '#' + i;
            $('.catalog-hide').fadeIn(200);
            $(item).addClass('show');

            genplan();
        });
        $('.hint-close, .hint-close-2').on('click', function () {
            $('.hint').removeClass('show');
            setTimeout(function () {
                $('.catalog-hide').fadeOut(300);
            }, 300);
        });
    };


    hover_popup('.plan-svg .pin-item, .plan-svg .path-item, .flats-chess .chess-item');
    hover_svg('.plan-svg .pin-item, .plan-svg .path-item');


    if ($('body').innerWidth() + 17 > lg) {

        // Переходим по ссылке при клике на svg
        $('body').on('click', '.pin-item, .path-item, .chess-item', function () {
            var i = $(this).data('i'),
                item = $('#' + i);

            if (item.hasAttr('data-href')) {
                var href = item.data('href');
                window.location = href;
            }
        });


    } else {
        click_popup('.plan-svg .pin-item:not(".--comm"), .plan-svg .path-item:not(".--comm"), .flats-chess .chess-item');

    }


    // Показать попап или hint
    //$(window).on("resize", function () {
    //    if ($('body').innerWidth() + 17 > lg) {
    //       $('.catalog-hide').show();
    //   } else {
    //        $('.catalog-hide').hide();
    //    }
    //});


    // Центровка пина по метке
    function pin_center() {
        $('.plan-svg *[data-pin]').each(function () {
            var i = $(this).data('pin'),
                block = $(this).closest('.plan-svg'),
                offset = $(this).offset();

            var ileft = (offset.left - block.offset().left) / block.innerWidth() * 100;
            var itop = (offset.top - block.offset().top) / block.innerHeight() * 100;

            var styles = {
                left: ileft + '%',
                top: itop + '%'
            };

            $('.plan-svg .pin-item[data-i="' + i + '"]').css(styles);

        });
    };
    pin_center();


    // Наведение на svg и pin
    function hover_svg(e) {
        $(e).hover(function () {
            var i = $(this).data('i');
            $('*[data-i="' + i + '"]').addClass('hover');
        }, function () {
            var i = $(this).data('i');
            $('*[data-i="' + i + '"]').removeClass('hover');
        });
    };


    // Наличие объекта или бронь
    $('.plan-svg .path-item').each(function () {
        var i = $(this).data('i'),
            item = $('#' + i);

        if (item.hasClass('booked')) {
            $('*[data-i="' + i + '"]').addClass('--lock');
        }
        if (!item.hasAttr('data-href')) {
            $('*[data-i="' + i + '"]').addClass('--sold');
        }
    });


    $('body').on('click', '.plan-title', function () {
        pin_center();
    });

    // Переход на схему домов при клике на квартал
    function genplan() {
        $('.--level-1, .minimap').on('click', '.pin-item, .path-item', function () {
            var i = $(this).data('i'),
                title = $('.pin-item[data-i="' + i + '"]').data('title'),
                item = $('.--level-2[data-level="' + i + '"]');


            if (item.hasAttr('data-level')) {
                $('.--level-1, .--level-2').hide();
                item.fadeIn(300).css('display', 'flex');
                $('.plan-title').text(title);

                // Миникарта
                $('.minimap').fadeIn(300);
                $('.minimap .path-item').removeClass('active');
                $('.minimap .path-item[data-i="' + i + '"]').addClass('active');

                $('.btn-back').css('display', 'flex');
                pin_center();
            }
            return false;
        });

        $('.genplan').on('click', '.btn-back', function () {
            var title = $(this).data('title');
            $('.plan-title').text(title);
            $('.--level-1').fadeIn(300).css('display', 'flex');
            $('.--level-2, .btn-back').hide();
            $('.minimap').fadeOut(300);
        });

    };
    genplan();


    //https://fancyapps.com/panzoom/api/options/
    $('#myPanzoom').each(function () {
        const container = document.getElementById("myPanzoom");
        const fp = new Panzoom(container, {
            //click: "toggleCover",
            click: false,
            bounce: false,
            dblClick: "toggleCover",

            wheel: false, //"zoom" | "pan" | false,
        });
    });


    // Элемент прилипает https://xn----7sbbaqhlkm9ah9aiq.net/news-new/lipkaya-bokovaya-panel-sticky-sidebar-na-jquery.html
    $('.--sticky').each(function () {
        var s_top = $(this).data('top'),
            s_bottom = $(this).data('bottom');

        if (s_top == undefined) {
            var s_top = 16;
        }
        if (s_bottom == undefined) {
            var s_bottom = 16;
        }

        stickySidebar = new StickySidebar(this, {
            topSpacing: s_top,
            bottomSpacing: s_bottom,
            resizeSensor: true,
            containerSelector: false,
            stickyClass: 'is-affixed',
            minWidth: xl
        });

        //stickySidebar.updateSticky(); // Обновление высоты фиксации
        //stickySidebar.destroy();
    });






    // Переход по ссылке с сохранением параметров
    $('.filter-block').on('click', '.button2', function () {
        var search = window.location.search,
            href = $(this).attr('href');
        document.location.href = href + search;
        //console.log(href + search);
        return false;
    });


    // Переход на форму другой страницы
    $('.--filter-main').on('click', '.button', function () {
        var search = window.location.search,
            action = $(this).closest('form').attr('action');
        document.location.href = action + search;
    });


    // animate
    $(window).on('load scroll', function () {
        $('.animate').each(function () {
            if ($(this).offset().top < ($(document).scrollTop() + window.innerHeight * 0.9)) {
                $(this).addClass('now-u-can-see-me');
            }
        });
    });


    // Показать куки
    function view_cookie() {
        if (!Cookies.get('view_cookie') == true) {
            $('body').append('<div class="cookie"><div class="cookie__text">Мы используем <a href="">cookie</a>,<br> чтобы вам было удобно.</div><div class="button --low cookie-btn">Ок</div></div>');
        }
        $('body').on('click', '.cookie-btn', function () {
            $('.cookie').fadeOut(300);
            Cookies.set('view_cookie', true, { expires: 30, path: '/' });
        });
    };
    view_cookie();


    // Иконка прокрутки в мобилке
    $(".skipit").on('mouseenter click', function (event) {
        $(this).fadeOut(300);
    });


    // scrollto
    $(".scrollto").click(function () {
        href = $(this).attr("href");
        destination = $(href).offset().top - 0;
        $("html:not(:animated),body:not(:animated)").animate({ scrollTop: destination }, 500);
        $('.header-top').removeClass('show');
        return false;
    });


    // msearch2 вывод результатов
    $(document).on('mse2_load', function (e, data) {
        decl_kv2();
        decl_kv();

        //more_filter();
        status_chess();

    });

    // Фильтр с главной, пересылка по url
    $('.form-js-fitter1 .--main').each(function () {
        var current = $(this).attr("action");
        $(document).on('mse2_load', function (e, data) {
            var search = window.location.search;
            document.location.href = current + search;
            console.log(current + search);
        });
    });


    // Quiz
    $('.quiz').each(function () {
        var current = '#' + $(this).attr("id"),
            id_quiz = $(this).attr("id"),
            q_step = 0,
            time_start = Math.floor($(this).data('time') + '000'),
            time_exit = Math.floor($(this).data('time-exit') + '000'),
            step_total = $(current + ' .quiz-step').length;

        // появление через сколько то секунд
        if (time_start > 0) {
            setTimeout(function () {
                if (!Cookies.get('quiz_start_' + id_quiz) == true & !$(current).hasClass('show')) {
                    $(current).css('display', 'flex').hide().fadeIn(300).addClass('show');

                    // запишем куку чтобы не доставал
                    Cookies.set('quiz_start_' + id_quiz, true, { expires: 4, path: '/' });

                    if (idmetrika != '' & idmetrika !== undefined) {
                        ym(idmetrika, 'reachGoal', 'quiz_start');
                    }

                    return false;
                }
            }, time_start);
        }

        // появление при выходе с сайта
        /*if (time_exit > 0) {
            setTimeout(function () {
                $(document).mouseleave(function () {
                    if (!Cookies.get('quiz_exit_2_' + id_quiz) == true & !$(current).hasClass('show')) {
                        $(current).css('display', 'flex').hide().fadeIn(300).addClass('show');

                        // запишем куку чтобы не доставал
                        Cookies.set('quiz_exit_2_' + id_quiz, true, { expires: 4, path: '/' });

                        // Цель - увидели квиз
                        if (idmetrika != '' & idmetrika !== undefined) {
                            ym(idmetrika, 'reachGoal', 'quiz_start');
                        }

                        return false;
                    }
                });
            }, time_exit);
        }*/

        // полоска прогресса
        function ProgressOnlinePlayers(play, max) {
            let progress = Math.floor(play / Math.floor(max + 1) * 100);
            $(current + ' .quiz-steps__num').text(play + ' из ' + Math.floor(max + 1));
            $(current + ' .quiz-steps__progress .--bg').css('width', progress + '%');
        }

        // полоска прогресса круглая с %
        function ProgressOnlinePlayers2(play, max) {
            let progress = Math.floor(play / Math.floor(max + 1) * 98);
            //$(current + ' .quiz-steps__num').text(play + ' из ' + Math.floor(max + 1));
            //$(current + ' .quiz-progressbar').css('--value', progress);
            //$(current + ' .quiz-progressbar').attr('aria-valuenow', progress);
            progress_bar(progress);
        }

        // проверка подарков
        function quiz_bonus() {
            $(current + ' .gift-item').each(function () {
                var i = $(this).data('id');

                if ($(current + ' .quiz__answers input[data-bonus="' + i + '"]').is(':checked')) {
                    $(this).addClass('--ok');
                    $(this).find('input').prop('checked', true);
                } else {
                    $(this).removeClass('--ok');
                    $(this).find('input').prop('checked', false);
                }

            });
        }

        // переключатель шагов вперед
        $(current).on('click', '.quiz-btn-next, .quiz-step .form-checkbox input:radio', function () {
            if ($(this).is(':checked')) {
                var timer = 700;
            } else {
                var timer = 0;
            }

            setTimeout(function () {
                $(current + ' .quiz-step,' + current + ' .quiz-start').removeClass('active').hide();

                q_step++;

                if (q_step > step_total) {
                    //$(current + ' .quiz-status').removeClass('active');
                    $(current + ' .quiz-status').addClass('max');
                    $(current + ' .quiz-end').fadeIn(100);

                    // Цель - дошли до формы
                    if (idmetrika != '' & idmetrika !== undefined) {
                        ym(idmetrika, 'reachGoal', 'quiz_end');
                    }
                } else {
                    $(current + ' .quiz-status').addClass('active').removeClass('max');
                    $(current + ' .quiz-end').hide();
                }

                if (q_step == 1) {
                    // Цель - началь проходить тест
                    if (idmetrika != '' & idmetrika !== undefined) {
                        ym(idmetrika, 'reachGoal', 'quiz_quests');
                    }
                }

                $(current + ' #q' + q_step).addClass('active').fadeIn(200);
                $(current + ' .quiz-question').fadeIn(300);

                ProgressOnlinePlayers(q_step, step_total);
                ProgressOnlinePlayers2(q_step, step_total);
                quiz_bonus();

            }, timer);

            if (!$(this).is(':checked')) {
                return false;
            }

        });

        // переключатель шагов назад
        $(current + ' .quiz-btn-prev').on('click', function () {
            $(current + ' .quiz-step').removeClass('active').hide();

            q_step--;

            if (q_step < 1) {
                $(current + ' .quiz-start').addClass('active').fadeIn(200);
                $(current + ' .quiz-status').removeClass('active');
                $(current + ' .quiz-question').hide();
            }

            if (q_step == step_total) {
                $(current + ' .quiz-end').hide();
                $(current + ' .quiz-status').removeClass('max');
            }

            $(current + ' #q' + q_step).addClass('active').fadeIn(200);

            ProgressOnlinePlayers(q_step, step_total);
            ProgressOnlinePlayers2(q_step, step_total);
            quiz_bonus();

            return false;
        });


        // подсчет скидки
        $(current + ' .form-checkbox--').on('click', function () {
            var q_price_dis = 0,
                q_area_dis = 0,
                q_remont_dis = 0,
                q_price_dis_max = $(current + ' form').data('discount-max');

            $(current + ' input:checked').each(function () {
                q_price_dis += isNaN(parseInt($(this).data('discount'))) ? 0 : parseInt($(this).data('discount'));
                q_area_dis += isNaN(parseInt($(this).data('area'))) ? 0 : parseInt($(this).data('area'));
                q_remont_dis += isNaN(parseInt($(this).data('remont'))) ? 0 : parseInt($(this).data('remont'));
            });

            if (q_price_dis >= q_price_dis_max) {
                var q_price_dis = q_price_dis_max;
            }

            var q_discont_pr = q_price_dis,
                q_discont_ar = parseInt(q_area_dis).toFixed(),
                q_discont_rem = parseInt(q_remont_dis).toFixed(),
                q_discont_all = parseInt(+q_discont_ar + +q_discont_pr).toFixed();

            //console.log('скидка мах ' + q_price_dis_max);
            //console.log('скидка ' + q_discont_pr);
            //console.log('лояльность ' + q_discont_ar);
            //console.log('плюс ремонт ' + q_discont_rem);
            //console.log('скидка вся ' + q_discont_all);


            if (q_discont_pr > 0 & q_discont_ar > 0) {
                //console.log(q_discont_pr + ' > 0 ' + q_discont_ar + ' > 0');
                $(current + ' .q-total-title').html('Ваша скидка на&nbsp;покупку квартиры<br> составляет <span>—&nbsp;' + q_discont_pr + '%</span> и&nbsp;<span>' + q_discont_ar + ' рублей</span> с&nbsp;каждого квадратного метра</span>');
                $(current + ' .quiz-in-discont').val('Скидка ' + q_discont_pr + '% и ' + q_discont_ar + 'р. с м²');

            } else if (q_discont_pr > 0) {
                //console.log(q_discont_pr + ' > 0 ');
                $(current + ' .q-total-title').html('Ваша <span>скидка на&nbsp;покупку квартиры</span><br> составляет <span>—&nbsp;' + q_discont_pr + '%</span>');
                $(current + ' .quiz-in-discont').val('Скидка ' + q_discont_pr + '%');

            } else if (q_discont_ar > 0) {
                //console.log(q_discont_ar + ' > 0');
                $(current + ' .q-total-title').html('Вы можете получить <span>скидку в&nbsp;' + q_discont_ar + '&nbsp;рублей</span> с&nbsp;каждого квадратного метра.');
                $(current + ' .quiz-in-discont').val('Скидка ' + q_discont_ar + 'р. с м²');

            } else {

                //console.log(' else ');
                $(current + ' .q-total-title').html('Заполните форму и&nbsp;менеджер подберет подходящую квартиру');
                $(current + ' .quiz-in-discont').val('Нет скидки');

            }
        });

        rank();
    });



    // Аккардион вопросы и ответы
    $('.--accordion').each(function () {
        var el = $(this);
        var el_block = $(this).find('.--hide');
        el.on('click', '.--on', function () {
            if (el.hasClass('active')) {
                el.removeClass('active');
                el_block.slideUp(300);
            } else {
                $('.--accordion .--on').removeClass('active');
                $('.--accordion .--hide').slideUp(300);
                el.addClass('active');
                el_block.slideDown(300);
            }
        });
    });



    // sense Fixed

    $(window).on('scroll', function () {
        $(".sense-fixed .cont").each(function () {
            var bottom_of_object = $(this).offset().top + $(this).outerHeight();
            var bottom_of_window = $(window).scrollTop() + $(window).height();
            var tab_id = $(this).attr('data-tab');

            if (bottom_of_window > bottom_of_object) {
                $("#" + tab_id).addClass('current');
                $(this).addClass('current');
            } else {
                $("#" + tab_id).removeClass('current');
                $(this).removeClass('current');
            }

        });
    });


    // Прогресс и наверх
    $('.progress-wrap').each(function () {
        var progressPath = document.querySelector('.progress-wrap path');
        var pathLength = progressPath.getTotalLength();
        progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
        progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
        progressPath.style.strokeDashoffset = pathLength;
        progressPath.getBoundingClientRect();
        progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
        var updateProgress = function () {
            var scroll = $(window).scrollTop();
            var height = $(document).height() - $(window).height();
            var progress = pathLength - (scroll * pathLength / height);
            progressPath.style.strokeDashoffset = progress;
        }
        updateProgress();
        $(window).scroll(updateProgress);
        var offset = 150;
        var duration = 550;
        jQuery(window).on('scroll', function () {
            if (jQuery(this).scrollTop() > offset) {
                jQuery('.progress-wrap').addClass('active-progress');
            } else {
                jQuery('.progress-wrap').removeClass('active-progress');
            }
        });
        jQuery('.progress-wrap').on('click', function (event) {
            event.preventDefault();
            jQuery('html, body').animate({ scrollTop: 0 }, duration);
            return false;
        })
    });


    // Прогресс бар круглый
    function progress_bar(count) {
        $('.progress-bar').each(function () {
            //var val = parseInt($(this).data('pct'));
            var val = parseInt(count);
            var $circle = $(this).find('.count');

            if (isNaN(val)) {
                val = 100;
            }
            else {
                var r = $circle.attr('r');
                var c = Math.PI * (r * 2);

                if (val < 0) { val = 0; }
                if (val > 100) { val = 100; }

                var pct = ((100 - val) / 100) * c;
                $circle.css({ strokeDashoffset: pct });
                $(this).attr('data-pct', val);
            }
        });
    }

});
// end контейнера